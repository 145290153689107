<template>
  <!-- 复选框弹框组件 -->
  <div>
    <a-modal
      title="添加商品"
      width="64%"
      :visible="modalVisible"
      okText="确定"
      cancelText="取消"
      @ok="ok"
      @cancel="
        () => {
          $emit('cancel');
        }
      "
    >
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="课程">
          <div class="search_box">
            <span style="margin-right: 10px">课程状态</span>
            <a-select
              v-model="putawayFlag"
              style="width: 120px; margin-right: 10px"
              @change="changePutawayFlag"
            >
              <a-select-option v-for="item in putawayFlagArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
            <a-input
              v-model="searchVal"
              placeholder="请输入课程名称"
              style="width: 200px"
              @pressEnter="searchClick"
            >
              <a-icon slot="prefix" type="search" />
            </a-input>
            <a-button
              icon="search"
              type="primary"
              @click="searchClick"
              :disabled="isDisableSearch"
              >搜索</a-button
            >
          </div>
          <!-- 表格 -->
          <a-table
            class="courseTable"
            :columns="columns"
            :scroll="{ y: 300 }"
            :data-source="tableData"
            :pagination="false"
            :loading="tableLoading"
            :row-selection="{
              selectedRowKeys: courseSelectedRowKeys,
              onChange: onSelectCourseChange,
              type: 'checkbox',
            }"
          >
            <div
              class="flexbox middle"
              slot="coverUrl"
              slot-scope="text, record"
            >
              <div><img v-viewer class="avatar" :src="record.coverUrl" /></div>
              <label>{{ record.name }}</label>
            </div>
            <span slot="putawayFlag" slot-scope="text, record">
              <a-badge
                v-if="record.putawayFlag == 0"
                status="error"
                :text="record.putawayFlagText"
              />
              <a-badge
                v-if="record.putawayFlag == 1"
                status="processing"
                :text="record.putawayFlagText"
              />
              <a-badge
                v-if="record.putawayFlag == 2"
                status="success"
                :text="record.putawayFlagText"
              />
              <a-badge
                v-if="record.putawayFlag == 3"
                status="warning"
                :text="record.putawayFlagText"
              />
            </span>
            <span slot="price" slot-scope="price, record">
              <span v-show="record.pricingType == 0">免费</span>
              <span v-show="record.pricingType == 1">{{ record.price }}</span>
              <span v-show="record.pricingType == 2">不支持单独售卖</span>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
import { TabExistsCourseInfoApi } from "@/request/api/indexManage"; // 课程Tab-去重
import { CourseListApi } from "@/request/api/marketingCenter"; // 课程管理列表
import { baseMixin } from "@/store/app-mixin";

export default {
  mixins: [baseMixin],
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      // 0 - 课程管理列表，1 - 课程Tab-去重
      type: Number,
      default: 0,
    },
    selectedRowKeys: {
      type: Array,
      default: [], // 复选框选中的CourseNo数组
    },
    selectedRows: {
      type: Array,
      default: [], // 已选择的内容
    },
  },
  created() {
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.putawayFlag.forEach((item) => this.putawayFlagArr.push(item));
    this.courseSelectedRowKeys = this.selectedRowKeys;
    this.courseSelectedRows = this.selectedRows;
    this.switchHandle();
    if (this.modalVisible) {
      this.$nextTick(() => {
        let selectWrap = document.querySelector(".courseTable .ant-table-body");
        selectWrap.addEventListener("scroll", () => {
          const scrollDistance =
            selectWrap.scrollHeight -
            selectWrap.scrollTop -
            selectWrap.clientHeight;
          if (scrollDistance <= 200 && this.count > this.tableData.length) {
            ++this.pageNo;
            let countAll = Math.ceil(this.count / this.pageSize);
            if (countAll < this.pageNo) return;
            this.switchHandle();
          }
        });
      });
    }
  },
  data() {
    return {
      searchVal: "", // 输入框的值
      putawayFlag: 2, // 课程状态的默认值 - 已上架
      putawayFlagArr: [{ key: "", value: "全部状态" }], // 课程状态数组
      isDisableSearch: false, // 搜索按钮是否禁用
      courseSelectedRowKeys: [], // 复选框选中的CourseNo数组
      courseSelectedRows: [],
      tableLoading: true, // 表格loading图
      count: 0, // 总条数
      pageNo: 1, // 当前页
      pageSize: 20, // 每页条数
      columns: [
        {
          title: "课程名称",
          dataIndex: "coverUrl",
          key: "coverUrl",
          scopedSlots: { customRender: "coverUrl" },
        },
        {
          title: "状态",
          dataIndex: "putawayFlag",
          key: "putawayFlag",
          width: "14%",
          scopedSlots: { customRender: "putawayFlag" },
        },
        {
          title: "课节数",
          dataIndex: "lessonsCount",
          key: "lessonsCount",
          width: "14%",
        },
        {
          title: "课程类型",
          dataIndex: "courseTypeText",
          key: "courseTypeText",
          width: "14%",
        },
        {
          title: "定价",
          key: "price",
          dataIndex: "price",
          width: "14%",
          scopedSlots: { customRender: "price" },
        },
      ],
      tableData: [],
    };
  },
  watch: {
    searchVal(newVal) {
      if (newVal.length <= 0) {
        this.tableData = [];
        this.switchHandle();
      }
    },
  },
  methods: {
    // 根据 type 的值 请求不同的接口数据
    switchHandle() {
      switch (this.type) {
        case 0:
          this.CourseListApi();
          break;
        case 1:
          this.TabExistsCourseInfoApi();
          break;
        default:
          break;
      }
    },
    // 0 - 课程管理列表
    CourseListApi() {
      this.courseLoading = true;
      CourseListApi({
        courseType: 0,
        name: this.searchVal,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        putawayFlag: this.putawayFlag,
      }).then((res) => {
        if (res.code == 200) {
          this.count = res.data.count;
          let newList = res.data.data;
          newList.map((item) => {
            item.key = item.courseNo;
          });
          this.tableData = this.tableData.concat(newList);
          this.tableLoading = false;
        }
      });
    },
    // 课程状态改变的回调
    changePutawayFlag(value) {
      this.pageNo = 1;
      this.tableData = [];
      this.putawayFlag = value;
      this.switchHandle();
    },
    // 回车和搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.tableData = [];
      this.switchHandle();
    },
    // 课程列表选择事件
    onSelectCourseChange(selectedRowKeys, selectedRows) {
      // this.courseSelectedRowKeys = selectedRowKeys;
      // this.courseSelectedRows = selectedRows;
      this.courseSelectedRowKeys = selectedRowKeys;
      this.courseSelectedRows.push(...selectedRows);
      var newArr = [];
      let newArr2 = [];
      var obj = {};
      for (var i = 0; i < this.courseSelectedRows.length; i++) {
        if (!obj[this.courseSelectedRows[i].courseNo]) {
          newArr.push(this.courseSelectedRows[i]);
          obj[this.courseSelectedRows[i].courseNo] = true;
        }
      }
      newArr.map((item) => {
        this.courseSelectedRowKeys.map((item2) => {
          if (item.courseNo == item2) {
            newArr2.push(item);
          }
        });
      });
      this.courseSelectedRows = newArr2;
    },
    // 确定按钮
    ok() {
      this.$emit("ok", this.courseSelectedRowKeys, this.courseSelectedRows);
    },
    // 获取课程列表数据
    TabExistsCourseInfoApi() {
      TabExistsCourseInfoApi({
        bizType: 2,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        putawayFlag: this.putawayFlag,
        name: this.searchVal,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          let newData = data.data.map((item) => {
            return JSON.parse(JSON.stringify(item).replace(/courseNo/g, "key"));
          });
          this.tableData = this.tableData.concat(newData);
          this.count = data.count;
          this.pageNo = data.pageNo;
          this.pageSize = data.pageSize;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search_box {
  margin-bottom: 20px;
  /deep/.ant-btn {
    margin-left: 20px;
  }
}
.avatar {
  width: 80px;
  margin-right: 10px;
}
// 改变滚动条样式
/deep/ .ant-table-body::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0);
}
/deep/ .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
/deep/ .ant-table-body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
